import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    SsoUserDataApiService,
    SsoUserDataModel,
} from '@pkv-frontend/data-domain/sso-user-data';
import { fetchSsoUserDataAction } from '../store/actions/sso-user-data.actions';
import { ssoUserDataSelector } from '../store/selectors/sso-user-data.selector';

@Injectable({ providedIn: 'root' })
export class SsoUserDataService {
    constructor(
        private readonly store$: Store,
        private readonly apiService: SsoUserDataApiService
    ) {}

    public readonly deprecatedSsoUserData$: Observable<SsoUserDataModel | null> =
        this.apiService.ssoUserData$;

    public readonly ssoUserData$: Observable<SsoUserDataModel | null> =
        this.store$.select(ssoUserDataSelector);

    public fetchSsoUserData(): void {
        this.store$.dispatch(fetchSsoUserDataAction());
    }
}
