import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SsoUserDataApiModel } from '../models/sso-user-data-api.model';
import { SsoUserDataModel } from '../models/sso-user-data.model';

@Injectable({ providedIn: 'root' })
export class SsoUserDataApiService {
    public readonly ssoUserData$: Observable<SsoUserDataModel | null> =
        this.fetch().pipe(
            catchError(() => of(null)),
            shareReplay({ bufferSize: 1, refCount: false })
        );

    constructor(private readonly httpClient: HttpClient) {}

    private fetch(): Observable<SsoUserDataModel | null> {
        return this.httpClient
            .get<SsoUserDataApiModel>('/api/public/v1/user/sso-user-data', {
                withCredentials: true,
            })
            .pipe(
                map((ssoUserDataApiModel) =>
                    this.mapToSsoUserDataModel(ssoUserDataApiModel)
                )
            );
    }

    private mapToSsoUserDataModel(
        ssoUserDataApiModel: SsoUserDataApiModel
    ): SsoUserDataModel {
        return {
            gender: ssoUserDataApiModel.gender ?? undefined,
            firstName: ssoUserDataApiModel.firstName ?? undefined,
            lastName: ssoUserDataApiModel.lastName ?? undefined,
            mobileNumber: ssoUserDataApiModel.mobileNumber ?? undefined,
            email: ssoUserDataApiModel.email ?? undefined,
            street: ssoUserDataApiModel.street ?? undefined,
            streetNumber: ssoUserDataApiModel.streetNumber ?? undefined,
            zipCode: ssoUserDataApiModel.zipCode ?? undefined,
            city: ssoUserDataApiModel.city ?? undefined,
            birthdate: ssoUserDataApiModel.birthdate ?? undefined,
            ssoUserId: ssoUserDataApiModel.ssoUserId,
            hasActiveContracts: ssoUserDataApiModel.hasActiveContracts,
            isPointPlanUser: ssoUserDataApiModel.isPointPlanUser,
        };
    }
}
