import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { exhaustMap, startWith } from 'rxjs/operators';
import { SsoUserDataService } from '@pkv-frontend/business-domain/sso-user-data';
import { PhpFrontendDataService } from '@pkv-frontend/data-domain/php-frontend-data';
import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';

// TODO: Create a UserData Slice in NgRx in PVPKV-14129
@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private readonly phpFrontendDataService: PhpFrontendDataService,
        private readonly ssoUserDataService: SsoUserDataService
    ) {}

    public readonly isLoggedIn$: Observable<boolean> = of(this.isLoggedIn);

    public readonly ssoUserData$: Observable<SsoUserDataModel | null> =
        this.isLoggedIn$.pipe(
            exhaustMap((isLoggedIn: boolean) =>
                isLoggedIn
                    ? this.ssoUserDataService.deprecatedSsoUserData$
                    : of(null)
            )
        );

    public readonly hasSsoUserActiveContracts$: Observable<boolean> =
        this.ssoUserData$.pipe(
            startWith(false),
            exhaustMap((ssoUserData: SsoUserDataModel | null) => {
                return of(!!ssoUserData?.hasActiveContracts);
            })
        );

    public readonly isPointPlanUser$: Observable<boolean> =
        this.ssoUserData$.pipe(
            map((ssoUserData) => ssoUserData?.isPointPlanUser || false)
        );

    public get isLoggedIn(): boolean {
        return this.phpFrontendDataService.data.isUserLoggedIn;
    }

    public get isConsultantLoggedIn(): boolean {
        return this.phpFrontendDataService.data.isConsultantLoggedIn;
    }

    public get userEmail(): string {
        return this.phpFrontendDataService.data.userEmail;
    }
}
