import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';

export const ssoUserDataFeatureKey = 'ssoUserData';

export interface SsoUserDataStateModel {
    ssoUserData: SsoUserDataModel | null;
    error?: string;
}

export interface SsoUserDataStateAware {
    [ssoUserDataFeatureKey]: SsoUserDataStateModel;
}
