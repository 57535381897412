import { createSelector } from '@ngrx/store';
import { SsoUserDataModel } from '@pkv-frontend/data-domain/sso-user-data';
import {
    SsoUserDataStateAware,
    SsoUserDataStateModel,
} from '../models/sso-user-data-state.model';

import { ssoUserDataStateSelector } from './sso-user-data-state.selector';

export const ssoUserDataSelector = createSelector<
    SsoUserDataStateAware,
    SsoUserDataStateModel,
    SsoUserDataModel | null
>(
    ssoUserDataStateSelector,
    (state: SsoUserDataStateModel): SsoUserDataModel | null => state.ssoUserData
);
