import { createReducer, on } from '@ngrx/store';
import {
    fetchSsoUserDataFailureAction,
    fetchSsoUserDataSuccessAction,
} from '../actions/sso-user-data.actions';
import { SsoUserDataStateModel } from '../models/sso-user-data-state.model';

export const initialState: SsoUserDataStateModel = {
    ssoUserData: null,
};

export const ssoUserDataReducers = createReducer(
    initialState,
    on(
        fetchSsoUserDataSuccessAction,
        (
            state: SsoUserDataStateModel,
            action: ReturnType<typeof fetchSsoUserDataSuccessAction>
        ): SsoUserDataStateModel => {
            return {
                ...state,
                ssoUserData: action.ssoUserData,
            };
        }
    ),
    on(
        fetchSsoUserDataFailureAction,
        (
            state: SsoUserDataStateModel,
            action: ReturnType<typeof fetchSsoUserDataFailureAction>
        ): SsoUserDataStateModel => {
            return {
                ...state,
                error: action.error,
            };
        }
    )
);
