import { Injectable } from '@angular/core';
import * as JQueryStatic from 'jquery';

// extend jQuery types to enable plugin
interface JQuery {
    cctSelect(data: unknown): void;
}
declare const jQuery: (elem: HTMLElement) => JQuery;
// hack to be able to use jQuery both as function as well as object for plugins
declare const $: typeof JQueryStatic & {
    fn: typeof JQueryStatic.fn & { cctSelect?: (data: unknown) => void };
};

@Injectable({
    providedIn: 'root',
})
export class JqueryCctselectService {
    public readonly canShowCrossSelling = $?.fn.cctSelect !== undefined;
    public cctSelect<T extends HTMLElement>(element: T, data: unknown): void {
        if ($?.fn.cctSelect) {
            jQuery(element).cctSelect(data);
        }
    }
}
