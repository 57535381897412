import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DeviceOutputEnum } from '@pkv-frontend/data-domain/php-frontend-data';
import { WindowReferenceService } from '@pkv-frontend/infrastructure/window-ref';

const deviceOutputCookieKey = 'deviceoutput';

@Injectable({
    providedIn: 'root',
})
export class DeviceOutputService {
    constructor(
        private readonly cookieService: CookieService,
        private readonly windowReferenceService: WindowReferenceService
    ) {}

    public getDeviceOutput(): DeviceOutputEnum {
        return this.cookieService.get(
            deviceOutputCookieKey
        ) as DeviceOutputEnum;
    }

    public isApp(): boolean {
        return [DeviceOutputEnum.App, DeviceOutputEnum.TabletApp].includes(
            this.getDeviceOutput()
        );
    }

    public isMobileApp(): boolean {
        return this.getDeviceOutput() === DeviceOutputEnum.App;
    }

    public isTablet(): boolean {
        return this.getDeviceOutput() === DeviceOutputEnum.Tablet;
    }

    public isTabletApp(): boolean {
        return this.getDeviceOutput() === DeviceOutputEnum.TabletApp;
    }

    public isAppleApp(): boolean {
        return (
            /iPad|iPhone|iPod/.test(
                this.windowReferenceService.nativeWindow.navigator.userAgent
            ) && this.isApp()
        );
    }
}
