import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DeviceOutputService } from '@pkv-frontend/data-domain/application';
import { LocalStorageService } from '@pkv-frontend/data-domain/persistence';
import { FeatureRouteEnum } from '@pkv-frontend/infrastructure/routes';
import {
    C24App,
    C24UpdateDataValues,
    ChattyStateEnum,
    ConversionTrackingConfig,
    PushPermissionDialogOptions,
    SetProductConfig,
    WindowReferenceService,
} from '@pkv-frontend/infrastructure/window-ref';

const conversionTrackingConfig: ConversionTrackingConfig = {
    productSSO: 'pkv',
    voucherCode: '',
    searchData: {
        type: 'closing',
        actionForeignId: '',
        productLabel: 'Versicherung PKV',
    },
};

const getConversionFunnelTrackedSessionKey = (leadId: number) =>
    'conversionFunnelTracked_' + leadId;

const setProductConfig: SetProductConfig = {
    productSSO: 'pkv',
    title: null,
};

@Injectable({
    providedIn: 'root',
})
export class C24AppService {
    private c24AppInstance?: Promise<C24App | undefined>;

    public get c24App(): Promise<C24App | undefined> {
        if (this.c24AppInstance) {
            return this.c24AppInstance;
        }

        if (this.windowReferenceService.nativeWindow.C24App) {
            this.c24AppInstance = Promise.resolve(
                this.windowReferenceService.nativeWindow.C24App
            );
        } else if (!this.deviceOutputService.isApp()) {
            this.c24AppInstance = Promise.resolve(undefined);
        } else {
            this.c24AppInstance = new Promise((resolve) => {
                const wireframeScript = this.document.createElement('script');
                wireframeScript.src = `/current-wireframe-assets/wireframe.min.js`;
                wireframeScript.onload = () => {
                    resolve(this.windowReferenceService.nativeWindow.C24App);
                };
                wireframeScript.onerror = () => {
                    resolve(undefined);
                };

                this.document.body.append(wireframeScript);
            });
        }

        return this.c24AppInstance;
    }

    constructor(
        @Inject(DOCUMENT)
        private readonly document: Document,
        private readonly windowReferenceService: WindowReferenceService,
        private readonly deviceOutputService: DeviceOutputService,
        private readonly localStorageService: LocalStorageService
    ) {}

    public async triggerPushPermissionDialog(
        options: PushPermissionDialogOptions
    ): Promise<void> {
        const c24App = await this.c24App;
        c24App?.triggerPushPermissionDialog(options);
    }

    public setTrackingProduct(): void {
        this.windowReferenceService.nativeWindow.C24App?.setProduct(
            setProductConfig
        );
    }

    public trackConversionFunnel(leadId: number): void {
        if (
            this.localStorageService.get<boolean>(
                getConversionFunnelTrackedSessionKey(leadId)
            )
        ) {
            return;
        }
        const config: ConversionTrackingConfig = {
            ...conversionTrackingConfig,
        };
        config.searchData.actionForeignId = leadId.toString();
        this.windowReferenceService.nativeWindow.C24App?.trackConversionFunnel(
            config,
            (success: boolean) => {
                if (success) {
                    this.localStorageService.set(
                        getConversionFunnelTrackedSessionKey(leadId),
                        true
                    );
                }
            }
        );
    }

    public setHeaderScrollingDisabled(disabled: boolean): void {
        this.windowReferenceService.nativeWindow.C24App?.setHeaderScrollingDisabled(
            disabled
        );
    }

    public async updateData(values: C24UpdateDataValues[]): Promise<void> {
        const c24App = await this.c24App;
        c24App?.updateData(
            values,
            (success: boolean, message: string | undefined) => {
                if (!success) {
                    throw new Error(
                        `C24App.updateData / Update Activities Call Failed. ${
                            message ?? ''
                        }`
                    );
                }
            }
        );
    }

    public async handleChattyState(): Promise<void> {
        const c24App = await this.c24App;
        const url = this.windowReferenceService.nativeWindow.location.href;

        setInterval(function () {
            c24App?.getChattySheetState((result: ChattyStateEnum) => {
                if (
                    result === ChattyStateEnum.Minimized &&
                    !url.includes(FeatureRouteEnum.ThirdViewPage)
                ) {
                    c24App?.closeChattySheet();
                }
            });
        }, 500);
    }
}
